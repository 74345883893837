button.p-button.p-component.p-button.p-component.p-button-rounded.p-button-info.p-button-icon-only,
li.sc-gsnTZi.dOhLPf,
ul.sc-bczRLJ.DWcfU.timeline-controls {
    display: none;
}

.bFdeWM {
    min-height: auto !important;
    max-width: max-content !important;
}

.ecNMKP{
    letter-spacing: 0.8px;
}


p {
    padding-top: 15px;
}


