/* Container styling */
.remove-account-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    background-color: #f4f4f4;
    padding: 20px;
    box-sizing: border-box; /* Include padding in height/width calculation */
}

/* Form styling */
.form {
    max-width: 400px;
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    box-sizing: border-box;
}

/* Input group styling */
.input-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

    /* Label styling */
    .input-group label {
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 14px;
    }

/* Input field styling */
.input-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

/* Button styling */
.button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin-top: 5px;
    width: 100%;
}

    .button:disabled {
        background-color: #ddd;
        cursor: not-allowed;
    }

/* Delete button styling */
.delete-button {
    background-color: #dc3545;
}

/* Message styling */
.message {
    color: #d9534f;
    margin-top: 10px;
    font-size: 14px;
}
